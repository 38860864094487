<script>
export default {
  name: 'TableauGroupe',
  props: {
    items: { required: false, type: Array, default: () => [] },
    loading: { reuqired: false, type: Boolean, default: false },
    nbTotal: { required: false, type: Number, default: 25 }
  },
  data () {
    return {
      limit: 10,
      maxShow: 10,
      headers: [
        { sortable: false, text: this.$t('classe.nom-classe'), value: 'titre' },
        { sortable: false, text: this.$t('classe.nombre-eleves'), value: 'usagers.total' },
        { sortable: false, text: this.$t('classe.enseignant'), value: 'enseignant' },
        { sortable: false, text: this.$t('classe.prend-fin'), value: 'fin' },
        { sortable: false, text: '', value: 'actions' }
      ],
      options: {
        page: 1,
        limit: 10,
        itemsPerPage: 10
      }
    }
  },
  methods: {
    displayNameFromItem (item) {
      return `${item?.usager?.info?.prenom} ${item?.usager?.info?.nom}`
    },
    endDateFromItem (item) {
      return this.$moment(item.finAt).locale(this.$i18n.locale).format('DD MMM YYYY')
    }
  }
}
</script>

<template lang="pug">
.groupe-tableau
  v-data-table(
    :headers="headers"
    :items='items'
    :loading='loading'
    :items-per-page.sync='limit',
    :server-items-length='nbTotal'
    :options.sync='options'
    :no-data-text='$t("alerte.aucun-resultat")'
    :mobile-breakpoint='0'
    :class='{"light" : $store.state.Preferences.darkMode, "white" : !$store.state.Preferences.darkMode}'
    :style='{borderRadius: "10px"}'
    :hide-default-footer='nbTotal < maxShow',
    light
  )
    template(v-slot:item.enseignant='{ item }')
      div {{ displayNameFromItem(item) }}

    template(v-slot:item.fin='{ item }')
      div {{ endDateFromItem(item) }}

    template(v-slot:item.actions='{ item }')
      .flex.justify-center
        v-btn.white--text(icon color='primary' :to="{ name: 'classesdetail___' + $i18n.locale, params: { lang: $i18n.locale, id: item.id } }")
          font-awesome-icon.f4(:icon="['fas', 'arrow-right']")
</template>
