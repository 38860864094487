<script>
import PageHeader from '@/components/shared/pageHeader'
import TableauCommun from '@/components/shared/tableauCommun'
import TableauGroupe from '../../components/groupes/TableauGroupe.vue'

export default {
  name: 'Classe',
  components: {
    PageHeader,
    TableauCommun,
    TableauGroupe
},
  data () {
    return {
      classes: [],
      totalNumberOfClass: 0,
      loading: true,
      page: 1
    }
  },
  created () {
    this.fetchClasses()
  },
  methods: {
    async fetchClasses () {
      this.loading = true
      const { data: { searchSalleClasses } } = await this.$apollo.query({
        query: require('@/graphql/queries/v2/searchSalleClasses.gql'),
        variables: {
          page: this.page,
          limit: 25
        }
      })
      this.totalNumberOfClass = searchSalleClasses.total
      this.classes = [...this.classes, ...searchSalleClasses.resultat]

      this.fetchMoreClasses()
    },
    fetchMoreClasses () {
      if (this.classes.length < this.totalNumberOfClass) {
        this.page++
        this.fetchClasses()
      } else {
        this.loading = false
      }
    }
  }
}
</script>

<template lang="pug">
.classes
  page-header(:title="$t('menu.mes-classes')", :backButtonLabel="$t('menu.home')", :backButtonTo="{ name: 'home___' + $i18n.locale, params: { lang: $i18n.locale } }")
  .content.mt3
    section.fullsize-section.animated.fadeIn
      tableau-groupe(
        :items="classes"
        :loading="loading"
        :nbTotal="totalNumberOfClass"
      )
</template>
